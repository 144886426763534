<template>
  <div class="login">
    <div class="login-main">
      <div class="login-header">
        <img src="@/assets/img/logo.png" width="56" height="56" alt="" />
        <div class="active">米鹊科技</div>
      </div>
      <div class="login-body">
        <div class="login-by-account">
          <el-form ref="accountForm" :rules="rules" :model="accountForm">
            <el-form-item prop="account">
              <el-input
                :prefix-icon="User"
                autocomplete="off"
                v-model="accountForm.account"
                placeholder="请输入手机号/昵称"
              >
                <template #prefix>
                  <el-icon class="el-input__icon"><User /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                :prefix-icon="Lock"
                v-model="accountForm.password"
                placeholder="请输入密码"
                type="password"
                :show-password="true"
                autocomplete="new-password"
              >
                <template #prefix>
                  <el-icon class="el-input__icon"><Lock /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              prop="checkCode"
              class="checkCode"
              style="margin-bottom: 15px"
            >
              <el-input
                :prefix-icon="Lock"
                v-model="accountForm.checkCode"
                placeholder="请输入验证码"
                autocomplete="new-password"
              >
                <template #prefix>
                  <el-icon class="el-input__icon"><Lock /></el-icon>
                </template>
              </el-input>
              <div class="img-box" @click="getCheckCodeImg">
                <img :src="checkCodeImg" alt="" />
              </div>
            </el-form-item>
            <el-form-item class="account-operate">
              <el-checkbox v-model="accountForm.remeberMe" label="记住我" name="type"></el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onLogin('accountForm')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { User, Lock } from "@element-plus/icons-vue";
export default {
  name: "login",
  components: {
    User,
    Lock,
  },
  data() {
    return {
      // 账号登录
      accountForm: {
        account: "",
        password: "",
        checkCode: "",
        remeberMe: false,
      },
      pointerEvents: true,
      rules: {
        account: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
        checkCode: [
          { required: true, message: "请填写验证码", trigger: "blur" },
        ],
      },
      checkCodeImg: null,
    };
  },

  mounted() {
    this.getCheckCodeImg();
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    keyDown(e) {
      if (e.keyCode == 13) {
        this.onLogin("accountForm");
      }
    },
    onLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .request("login", {
              account: this[formName].account,
              password: this[formName].password,
              code: this[formName].checkCode,
            })
            .then((res) => {
              localStorage.setItem("token", res.result.Authorization);
              localStorage.setItem("userId", res.result.user_id);
              localStorage.setItem("userName", this[formName].account);
              this.$router.push({
                path: "/home",
              });
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    getCheckCodeImg() {
      var xmlhttp;
      xmlhttp = new XMLHttpRequest();
      xmlhttp.open(
        "get",
        this.$http.baseURL + this.$api.httpPorts.captchaImage.url,
        true
      );
      xmlhttp.responseType = "blob";
      xmlhttp.onload = (e) => {
        console.log(e);
        if (e.currentTarget.status == 200) {
          var blob = e.currentTarget.response;
          let img = document.createElement("img");
          img.onload = () => {
            window.URL.revokeObjectURL(img.src);
          };
          this.checkCodeImg = window.URL.createObjectURL(blob);
        }
      };
      xmlhttp.send();
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: url("../assets/img/loginBg.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .login-main {
    margin-right: 7%;
    width: 400px;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 1px 1px 12px #eee;
    border-radius: 10px;
    .login-header {
      height: 110px;
      line-height: 110px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        display: inline-block;
        vertical-align: middle;
        font-size: 25px;
        font-weight: 700;
        color: #969696;
        cursor: pointer;
      }
      .active {
        // color: #0a2463;
        color: #409eff;
        margin-left: 10px;
      }
      .fg {
        width: 2px;
        height: 12px;
        background-color: #969696;
        // border-radius: 50%;
        margin: auto 15px;
      }
    }
    .login-body {
      padding: 0 50px;
      box-sizing: border-box;
    }
    .login-code {
      width: 35px;
      background-color: rgba(64, 158, 255, 0.7);
      padding: 4px 10px;
      font-size: 12px;
      border-radius: 15px;
      color: #fff;
      font-style: normal;
      cursor: pointer;
      pointer-events: auto;
    }
    .login-code.ok {
      background-color: #409eff;
    }
    .isPointer {
      background-color: rgba(64, 158, 255, 0.7);
      pointer-events: none !important;
    }
    .el-form-item__content {
      .el-button {
        width: 100%;
        border-radius: 25px;
        background-color: #409eff;
        border: 1px solid #409eff;
      }
    }
  }
  .checkCode {
    /deep/.el-form-item__content {
      flex-wrap: nowrap;
      .img-box {
        margin-left: 15px;
      }
      img {
        vertical-align: middle;
      }
    }
  }
  .tip {
    color: #606266;
    text-align: center;
    cursor: pointer;
  }
}
</style>
